
import { ref, reactive, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/LotAdjustmentsEnums.ts";
import { Actions as LotActions } from "@/store/enums/LotEnums.ts";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import AddAdjustment from '@/views/lots/adjustments/AddAdjustment.vue';
import EditAdjustment from '@/views/lots/adjustments/EditAdjustment.vue';
import moment from 'moment'
export default {
  name : 'AdjustmentsList',
  components : {
    Table,
    AddAdjustment,
    EditAdjustment,
    DeleteRecord
  },
  setup(){

    const store = useStore();

    onMounted(() => {

      store.dispatch(LotActions.GET_ALL_LOTS)

      setCurrentPageBreadcrumbs("Lots",[
        {title : "Lot Adjustments",to : "/lots/adjustments"}
      ]);
    });

    const actions = computed(() => {
      return Actions;
    })



    const columns = [
      {name : 'adjustDate', title : 'Date', sortable : true, sort : 'desc', searchable : true},
      {name : 'productLot', title : 'Product / Lot', sortable : true, sort : null, searchable : true},
      {name : 'adjustment', title : 'Adjustment', sortable : true, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ]

    return {
      columns,
      actions,
      moment
    }
  }
}

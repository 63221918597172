
import { ref, reactive, onMounted, computed, isReactive, watch } from 'vue';
import { useStore } from "vuex";
export default {
  name : 'Table',
  props : {
    columns : {
      type : [Array,Object]
    },
    action : {
      type : [String]
    },
    id : {
      type : [Number]
    },
    additionalFilters : {
      type: [Array,Object]
    },
    searchEnabled : {
      type: [Boolean],
      default : true
    },
    showPerPageEnabled : {
      type: [Boolean],
      default : true
    },
    searchApiEnabled : {
      type: [Boolean],
      default : true
    }

  },
  setup(props){

    const rndStr = (len) => {
    	let text = " "
    	const chars = "abcdefghijklmnopqrstuvwxyzAFFyhuuiGIUASfTUrftyas"

      for( let i=0; i < len; i++ ) {
				text += chars.charAt(Math.floor(Math.random() * chars.length))
      }

			return text
		}

    const store = useStore();

    const myColumns = reactive(props.columns)

    const keyword = ref('');

    const page = ref(1);

    const meta = reactive({
      value : {}
    })
    const totalPages = ref(0);

    const limit = ref(10);

    const limitOptions = reactive([10,20,50])

    const tableLoading = ref(false);

    const sort = computed(() => {
      let s = {}
      myColumns.forEach((item) => {
        if(item.sortable && item.sort != null){
          s = {column : item.name, type : item.sort}
        }
      });
      return s;
    })

    const searchable = computed(() => {
      const items = {}
      myColumns.forEach((item) => {
        if(typeof item.searchable !== 'undefined'){
          items[item.name] = item.searchable
        }
      });
      return items;
    })


    const rows = reactive({
      value : []
    });



    const search = () => {
      if(props.searchApiEnabled){

        tableLoading.value = true;

        store.dispatch(props.action,{
          id : props.id ? props.id : null,
          keyword : keyword.value,
          page : page.value,
          limit : limit.value,
          sort : sort.value,
          searchable : searchable.value,
          additionalFilters : props.additionalFilters ? props.additionalFilters : null

        }).then((response) => {

          rows.value = response.data
          meta.value = response.meta
          totalPages.value = response.meta.last_page
          tableLoading.value = false;

        });
      }
    }

    const sortByColumn = (column) => {
      page.value = 1;
      if(column.sortable){
        myColumns.forEach((item) => {
          if(item.sortable && item.name != column.name){
            item.sort = null
          }
        });
        column.sort = column.sort == 'asc' ? 'desc' : 'asc';
        search();
      }
    }

    const pageDown = () => {
      if(page.value > 1){
        page.value--;
        search();
      }
    }

    const pageUp = () => {
      if(page.value < totalPages.value){
        page.value++;
        search();
      }
    }

    watch(() => props.additionalFilters, () => {
      search();
    },{deep:true});

    onMounted(() => {
      search();
    })

    return {
      keyword,
      page,
      limit,
      limitOptions,
      sortByColumn,
      rows,
      myColumns,
      search,
      meta,
      pageDown,
      pageUp,
      tableLoading,
      rndStr,
      props
    }
  }
}

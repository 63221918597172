<template>
  <div>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModalAdjustment">
    Add Adjustment
  </button>
  <!-- Modal -->
  <div class="modal fade" id="exampleModalAdjustment" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Adjustment</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3" v-show="success">
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                Successfully added adjustment!
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Lot</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.lot_id}" v-model="formData.lot_id">
                <option selected disabled value="null">Select Lot</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myAllLots" :key="key">{{item.code}} / {{item.lotNumber}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.lot_id">{{errors.value && errors.value.lot_id[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Adjust Date</label>
              <input type="date" class="form-control" :class="{'is-invalid' : errors.value && errors.value.adjustDate}" v-model="formData.adjustDate">
              <span class="text-danger" v-if="errors.value && errors.value.adjustDate">{{errors.value && errors.value.adjustDate[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Adjustment</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.adjustment}" v-model="formData.adjustment">
              <span class="text-danger" v-if="errors.value && errors.value.adjustment">{{errors.value && errors.value.adjustment[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label fw-bold fs-6">Note</label>
              <textarea class="form-control" :class="{'is-invalid' : errors.value && errors.value.note}" v-model="formData.note"></textarea>
              <span class="text-danger" v-if="errors.value && errors.value.note">{{errors.value && errors.value.note[0]}}</span>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="close-modal-adjustment">Close</button>
          <button
            type="submit"
            id="addAdjustmentSubmitBtn"
            class="btn btn-primary fw-bolder"
            @click="submitForm();"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Actions } from "@/store/enums/LotAdjustmentsEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted, computed } from 'vue'
import Swal from "sweetalert2/dist/sweetalert2.min.js";
 export default {
   name : 'AddAdjustment',
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const success = ref(false)
     const errors = reactive({
       value : {}
     });


     const formData = reactive({
       lot_id : null,
       adjustDate : '',
       adjustment : null,
       note : ''
     })

     onMounted(() => {

       const myModalEl = document.getElementById('exampleModalAdjustment') as any
       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         errors.value = [];
         formData.lot_id = null;
         formData.adjustDate = '';
         formData.adjustment = null;
         formData.note = '';
       })
     })


     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('addAdjustmentSubmitBtn') as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.ADD_LOT_ADJUSTMENT,formData).then((r) => {

         submitButton.value?.removeAttribute("data-kt-indicator");

         if(r.success){
           errors.value = [];
           success.value = true;

           setTimeout(function(){

             const closeModal = document.getElementById('close-modal-adjustment') as HTMLElement;
             closeModal.click();
             emit('input');

           },1000)
         }

       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

         errors.value = response.data.errors;

         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       props,
       formData,
       errors,
       store,
       submitForm,
       success
     }
   }
 }
</script>

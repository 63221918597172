<template>
  <div class="position-relative overflow-hidden">
    <slot :name="'extra'" :search="search" v-if="$slots['extra']"></slot>

    <div class="table-loader" v-if="tableLoading"></div>
    <div class="row mb-3">
      <div class="col-12 col-lg-2 mb-3 mb-lg-0 me-auto" v-if="props.showPerPageEnabled">
        <select class="form-select" v-model="limit" @change="page = 1;search();">
          <option :value="item" v-for="(item,key) in limitOptions" :key="key">{{item}}</option>
        </select>
      </div>
      <div class="col-12 col-lg-5 ms-auto" v-if="props.searchEnabled">
        <div class="input-group mb-3">
          <input type="text" class="form-control" v-model="keyword" placeholder="Start typing" @keyup="page = 1;search();">
          <span class="input-group-text" id="basic-addon2"><i class="fs-1-5 la la-search"></i></span>
        </div>
      </div>
    </div>

    <div class="table-responsive mb-3">
      <table class="table table-sortable table-striped">
        <thead>
          <tr>
            <th scope="col" class="text-nowrap" :class="{'column-sortable' : item.sortable, 'asc' : item.sort == 'asc','desc' : item.sort == 'desc'}" @click="sortByColumn(item)" v-for="(item,key) in myColumns" :key="key">
                {{item.title}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row,key) in rows.value" :key="key+rndStr(35)" v-show="rows.value.length > 0">

            <td v-for="(column,k) in myColumns" :key="'c'+k">

              <slot :name="column.name" :row="row" :search="search" v-if="$slots[column.name]"></slot>

              <span v-else>{{row[column.name]}}</span>

            </td>

          </tr>

          <tr v-show="rows.value.length == 0">
            <td :colspan="columns.length">
              <div class="p-2 text-center">No results to show</div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <nav aria-label="Page navigation example" v-if="meta.value && rows.value.length > 0">
      <ul class="pagination">
        <li class="page-item">
          <a class="page-link" href="javascript:;" aria-label="Previous" @click="pageDown">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>

        <li class="page-item" :class="{'active' : item.active}" v-for="(item,key) in meta.value.links" :key="key" v-show="key > 0 && key < (meta.value.links.length - 1)"><a class="page-link" href="javascript:;" @click="page = Number(item.label);search();" v-html="item.label"></a></li>

        <li class="page-item">
          <a class="page-link" href="javascript:;" aria-label="Next" @click="pageUp">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script lang="ts">
import { ref, reactive, onMounted, computed, isReactive, watch } from 'vue';
import { useStore } from "vuex";
export default {
  name : 'Table',
  props : {
    columns : {
      type : [Array,Object]
    },
    action : {
      type : [String]
    },
    id : {
      type : [Number]
    },
    additionalFilters : {
      type: [Array,Object]
    },
    searchEnabled : {
      type: [Boolean],
      default : true
    },
    showPerPageEnabled : {
      type: [Boolean],
      default : true
    },
    searchApiEnabled : {
      type: [Boolean],
      default : true
    }

  },
  setup(props){

    const rndStr = (len) => {
    	let text = " "
    	const chars = "abcdefghijklmnopqrstuvwxyzAFFyhuuiGIUASfTUrftyas"

      for( let i=0; i < len; i++ ) {
				text += chars.charAt(Math.floor(Math.random() * chars.length))
      }

			return text
		}

    const store = useStore();

    const myColumns = reactive(props.columns)

    const keyword = ref('');

    const page = ref(1);

    const meta = reactive({
      value : {}
    })
    const totalPages = ref(0);

    const limit = ref(10);

    const limitOptions = reactive([10,20,50])

    const tableLoading = ref(false);

    const sort = computed(() => {
      let s = {}
      myColumns.forEach((item) => {
        if(item.sortable && item.sort != null){
          s = {column : item.name, type : item.sort}
        }
      });
      return s;
    })

    const searchable = computed(() => {
      const items = {}
      myColumns.forEach((item) => {
        if(typeof item.searchable !== 'undefined'){
          items[item.name] = item.searchable
        }
      });
      return items;
    })


    const rows = reactive({
      value : []
    });



    const search = () => {
      if(props.searchApiEnabled){

        tableLoading.value = true;

        store.dispatch(props.action,{
          id : props.id ? props.id : null,
          keyword : keyword.value,
          page : page.value,
          limit : limit.value,
          sort : sort.value,
          searchable : searchable.value,
          additionalFilters : props.additionalFilters ? props.additionalFilters : null

        }).then((response) => {

          rows.value = response.data
          meta.value = response.meta
          totalPages.value = response.meta.last_page
          tableLoading.value = false;

        });
      }
    }

    const sortByColumn = (column) => {
      page.value = 1;
      if(column.sortable){
        myColumns.forEach((item) => {
          if(item.sortable && item.name != column.name){
            item.sort = null
          }
        });
        column.sort = column.sort == 'asc' ? 'desc' : 'asc';
        search();
      }
    }

    const pageDown = () => {
      if(page.value > 1){
        page.value--;
        search();
      }
    }

    const pageUp = () => {
      if(page.value < totalPages.value){
        page.value++;
        search();
      }
    }

    watch(() => props.additionalFilters, () => {
      search();
    },{deep:true});

    onMounted(() => {
      search();
    })

    return {
      keyword,
      page,
      limit,
      limitOptions,
      sortByColumn,
      rows,
      myColumns,
      search,
      meta,
      pageDown,
      pageUp,
      tableLoading,
      rndStr,
      props
    }
  }
}
</script>


import { Actions } from "@/store/enums/LotAdjustmentsEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted, computed } from 'vue'
import Swal from "sweetalert2/dist/sweetalert2.min.js";
 export default {
   name : 'AddAdjustment',
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const success = ref(false)
     const errors = reactive({
       value : {}
     });


     const formData = reactive({
       lot_id : null,
       adjustDate : '',
       adjustment : null,
       note : ''
     })

     onMounted(() => {

       const myModalEl = document.getElementById('exampleModalAdjustment') as any
       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         errors.value = [];
         formData.lot_id = null;
         formData.adjustDate = '';
         formData.adjustment = null;
         formData.note = '';
       })
     })


     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('addAdjustmentSubmitBtn') as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.ADD_LOT_ADJUSTMENT,formData).then((r) => {

         submitButton.value?.removeAttribute("data-kt-indicator");

         if(r.success){
           errors.value = [];
           success.value = true;

           setTimeout(function(){

             const closeModal = document.getElementById('close-modal-adjustment') as HTMLElement;
             closeModal.click();
             emit('input');

           },1000)
         }

       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

         errors.value = response.data.errors;

         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       props,
       formData,
       errors,
       store,
       submitForm,
       success
     }
   }
 }

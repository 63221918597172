<template>
  <div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">List of Adjustments</h3>
        </div>
        <!-- <router-link
          to="/products/component/add"
          class="btn btn-primary align-self-center ms-auto me-2"
          >Add Component</router-link> -->

          <!-- <router-link
            to="/products/composite/add"
            class="btn btn-primary align-self-center"
            >Add Composite</router-link> -->
      </div>
      <div class="card-body p-7">

        <Table :columns="columns" ref="table" :action="actions.SEARCH_LOT_ADJUSTMENTS">

          <template v-slot:extra="slotProps">

            <div class="mb-10">

              <AddAdjustment @input="slotProps.search()"/>

            </div>

          </template>


          <template v-slot:adjustDate="slotProps">

            <div>{{moment(new Date(slotProps.row.adjustDate)).format('DD MMM YYYY')}}</div>

          </template>


          <template v-slot:actions="slotProps">

            <div>

              <div class="btn-group" role="group">

                <EditAdjustment :adjustment="slotProps.row" @input="slotProps.search()" />

                <DeleteRecord :id="slotProps.row.id" :action="actions.DELETE_LOT_ADJUSTMENT" :callback="slotProps.search"/>

              </div>

            </div>

          </template>

        </Table>

      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ref, reactive, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/LotAdjustmentsEnums.ts";
import { Actions as LotActions } from "@/store/enums/LotEnums.ts";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import AddAdjustment from '@/views/lots/adjustments/AddAdjustment.vue';
import EditAdjustment from '@/views/lots/adjustments/EditAdjustment.vue';
import moment from 'moment'
export default {
  name : 'AdjustmentsList',
  components : {
    Table,
    AddAdjustment,
    EditAdjustment,
    DeleteRecord
  },
  setup(){

    const store = useStore();

    onMounted(() => {

      store.dispatch(LotActions.GET_ALL_LOTS)

      setCurrentPageBreadcrumbs("Lots",[
        {title : "Lot Adjustments",to : "/lots/adjustments"}
      ]);
    });

    const actions = computed(() => {
      return Actions;
    })



    const columns = [
      {name : 'adjustDate', title : 'Date', sortable : true, sort : 'desc', searchable : true},
      {name : 'productLot', title : 'Product / Lot', sortable : true, sort : null, searchable : true},
      {name : 'adjustment', title : 'Adjustment', sortable : true, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ]

    return {
      columns,
      actions,
      moment
    }
  }
}
</script>

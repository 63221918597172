
  import { useStore } from "vuex";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  export default {
    name : 'DeleteRecord',
    props : {
      id : {
        type : Number
      },
      action : {
        type : String
      },
      callback : {
        type : Function
      }
    },
    setup(props,{ emit }){
      const store = useStore();
      const deleteRecord = function(){
        Swal.fire({
          title: 'Are you sure want to delete?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch(props.action,{id : props.id}).then(() => {
              emit('input')
              props.callback();
              Swal.fire(
                'Deleted!',
                'Record has been deleted.',
                'success'
              )
            }).catch((error) => {
              if(error.data.errors){
                Swal.fire({
                  text: error.data.errors.message[0],
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger"
                  }
                });
              }
            })

          }
        })
      }
      return {
        deleteRecord
      }
    }
  }
